/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "senter",
            "endpoint": "https://ohdokyaalf.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "senter1",
            "endpoint": "https://iu0gm3u9o2.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        },
        {
            "name": "webhooks",
            "endpoint": "https://npklumfirb.execute-api.ap-southeast-2.amazonaws.com/production",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-southeast-2:42d88cd4-335f-445d-bd91-8accf7852049",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_iaSfdhzw2",
    "aws_user_pools_web_client_id": "4kfv47abutnoajk6gj4l04urdb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "integration-dev-production",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "http://integration-dev-production.s3-website-ap-southeast-2.amazonaws.com",
    "aws_user_files_s3_bucket": "lists72302-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
